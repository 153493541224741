import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import * as yup from 'yup';
import moment from 'moment';

import config from '../config';
import { isValidDateDDMMYYYY } from 'wallet/util';
import { formService } from '../services';

const createSchema = (form) => {
  const values = {};
  const validationSchema = {};
  const dependent_fields = {};
  const touched = {};

  form.forEach((section) => {
    let fields = section.fields;

    fields.forEach((field) => {
      values[field.name] = '';
      touched[field.name] = false;
      validationSchema[field.name] = yup.string();

      if (field.type === 'email') {
        validationSchema[field.name] = validationSchema[field.name].email('Email invalido');
      }

      if (field.type === 'numeric') {
        validationSchema[field.name] = validationSchema[field.name].matches(
          /^[0-9]+$/,
          'Solo números',
        );
      }

      if (field.type === 'date') {
        validationSchema[field.name] = validationSchema[field.name].test(
          'birth_date',
          'Formato incorrecto, ejemplo: 01/01/1990',
          (value) => {
            return isValidDateDDMMYYYY(value);
          },
        ).test(
          'birth_date',
          'Fecha incorrecta',
          value => isValidDateDDMMYYYY(value) && moment() > moment(value, 'DD/MM/YYYY')
        )
      }

      if (field.min) {
        validationSchema[field.name] = validationSchema[field.name].min(
          field.min,
          `${field.label}, mínimo ${field.min} ${field.type === 'text' ? 'caracteres' : 'digitos'
          }`,
        );
      }

      if (field.max) {
        validationSchema[field.name] = validationSchema[field.name].max(
          field.max,
          `${field.label}, máximo ${field.max} ${field.type === 'text' ? 'caracteres' : 'digitos'
          }`,
        );
      }

      if (field.length) {
        validationSchema[field.name] = validationSchema[field.name].length(
          field.length,
          `${field.label} de ${field.length} digitos`,
        );
      }

      if (field.type === 'select') {
        if (field.dependent_field) {
          let fields_dependent;

          field.dependent_field.forEach(dep => {
            fields_dependent = fields.filter(val => (val.name === dep || val.name_key === dep)).map(val => ({ name: dep, id: val.id }));
          })

          dependent_fields[field.name_key] = fields_dependent;
        }
        values[field.name_key] = '';
        touched[field.name_key] = false;
        validationSchema[field.name_key] = yup.string();
      }

      if (field.visible) {
        validationSchema[field.name] = validationSchema[field.name].when(
          field.visible.key,
          {
            is: field.visible.value,
            then: validationSchema[field.name].required(`${field.label} requerido`),
            otherwise: validationSchema[field.name].nullable().transform((o, c) => o === "" ? null : c),
          },
        );
      } else if (field.required) {
        validationSchema[field.name] = validationSchema[field.name].required(
          `${field.label} requerido`,
        );
      }
    });
  });

  return {
    values,
    touched,
    dependent_fields,
    validationSchema,
    validationSchemaGlobal: yup.object().shape(validationSchema),
    errors: {},
    enabled: {},
    isValidForm: false,
  };
};

const useForms = (type, data) => {
  const {
    loaded,
    isBusinessParser,
  } = data;
  const { useUser } = config.getInstance().getConfiguration();
  const { user } = useUser();
  const { category } = user;
  const [form, setForm] = useState({});
  const [sections, setSections] = useState([]);
  const [LoadSections, setLoadSections] = useState(false);
  const isFetchSections = useRef(null);
  const isLoaded = useRef(null);

  useMemo(() => {
    if (sections.length > 0) {
      setForm(prevState => {
        return {
          ...createSchema(sections),
          ...prevState,
        }
      });
    }
  }, [sections]);

  useEffect(() => {
    if (!isFetchSections.current) {
      isFetchSections.current = true;

      (async () => {
        try {
          if (user && type) {
            setLoadSections(prevState => !prevState);
            const response = await formService.getFormByType(user.headers, type);
            setLoadSections(prevState => !prevState);
            setSections(response.data)
          }
        } catch (error) {
          // return Promise.reject(error);
        }
      })();
    }
  }, []);

  const updateDependentFields = useCallback((name, options) => {
    setSections(sections.map(section => {
      section.fields.map(field => {
        if (field.name === name || field.name_key === name) {
          field.options = options;
        }
        return field;
      })
      return section;
    }));
  }, [sections, setSections]);

  const getDependientOptions = useCallback((value, dependients_fields) => {

    dependients_fields.forEach(async dep => {
      try {
        const response = await formService.getSelectOptions(user.headers, dep.id, value);
        updateDependentFields(dep.name, response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    })

  }, [formService, updateDependentFields]);

  const handleChange = useCallback(name => (value, first) => {
    const { values, errors, enabled, validationSchema, validationSchemaGlobal, dependent_fields, touched } = form;

    let isValidForm = true;

    for (let v in values) {
      if (values.hasOwnProperty(v)) {
        touched[v] = false;
      }
    }

    if (!values.hasOwnProperty(name)) {
      return;
    }

    values[name] = value;
    touched[name] = true;

    if (dependent_fields[name]) {
      try {
        Object.values(dependent_fields[name])
          .forEach(val => {
            values[val.name] = '';
            values[`${val.name}_select`] = '';
          })
        if (value) getDependientOptions(value, dependent_fields[name]);
      } catch { }
    }
    try {
      validationSchema[name].validateSync(value);
      if (errors[name]) {
        delete errors[name];
      }
    } catch (error) {
      errors[name] = error.message;

      if (first) {
        enabled[name] = true;
      }
    }

    try {
      validationSchemaGlobal.validateSync(values);
    } catch (error) {
      isValidForm = false;
    }

    setForm(prevState => ({
      ...prevState,
      values,
      errors,
      enabled,
      touched,
      isValidForm,
    }));
  }, [form])

  useEffect(() => {
    if (!loaded || isLoaded.current || Object.keys(form).length < 1 || sections.length < 1) return;

    isLoaded.current = true;

    sections.forEach(section => {
      section.fields.forEach(field => {
        if (user[field.name] && category !== 'business') {
          if (field.type === 'date') {
            handleChange(field.name)(moment(user[field.name]).format('DD/MM/YYYY'), true)
          } else {
            handleChange(field.name)(user[field.name], true)
          }
        } else if (isBusinessParser && category === 'business' && user.business_user[`lr_${field.name}`]) {
          if (field.type === 'date') {
            handleChange(field.name)(moment(user.business_user[`lr_${field.name}`]).format('DD/MM/YYYY'), true)
          } else {
            handleChange(field.name)(user.business_user[`lr_${field.name}`], true)
          }
        } else if (user[field.name_key]) {
          handleChange(field.name_key)(user[field.name_key], true)
        } else if (field.name_key === 'country') {
          handleChange('country_select')(user.country_name, true)
          handleChange('country')(user.iso_code_country, true)
        }
      })
    })

  }, [sections, form, loaded, handleChange]);

  return {
    form,
    sections,
    LoadSections,
    handleChange,
  };
};

export default useForms;
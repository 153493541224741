import { useState, useEffect, useMemo, useCallback } from 'react';
import * as yup from 'yup';

import useServices from '../useServices';
import cryptoService from '../../services/crypto';

const validationSchema = {
  network: yup.string().required('La red es requerida.'),
  network_key: yup.string().required('La red es requerida.'),
  hash: yup
    .string()
    .max(100, 'Máximo 100 caracteres')
    .matches(/^\S*\S[\s\S]*/, 'No empezar con espacios en blanco')
    .required('TxID/TxHash requerida.'),
  address: yup
    .string()
    .max(150, 'Máximo 150 caracteres')
    .matches(/^\S*\S[\s\S]*/, 'No empezar con espacios en blanco')
    .required('Dirección requerida.'),
};


const UseLostFunds = ({ headers }) => {
  const [networks, setNetworks] = useState([]);
  const [values, setValue] = useState({
    network: '',
    network_key: '',
    hash: '',
    address: '',
  });
  const [errors, setErrors] = useState({
    network: 'La red es requerida.',
    network_key: 'La red es requerida.',
    hash: 'TxID/TxHash requerida.',
    address: 'Dirección requerida.',
  });
  const { getCryptoNetworks } = useServices();

  const isFormValid = useMemo(() => {
    return !Object.keys(errors).length < 1;
  }, [values, errors]);

  const handleChange = (name) => (value) => {
    try {
      setValue(prev => ({
        ...prev,
        [name]: value,
      }));

      validationSchema[name].validateSync(value);
      if (errors[name]) {
        setErrors(prev => {
          delete prev[name];
          return prev;
        });
      }
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        [name]: error.message
      }));
    }
  };

  const handleSubmit = useCallback(async () => {
    try {
      const response = await cryptoService.lostFundsAlert(headers, values);
      delete response.headers;
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }, [values, headers]);

  useEffect(() => {
    (async () => {
      try {
        const networks = await getCryptoNetworks({});
        setNetworks(networks?.data?.map(net => ({ label: net.attributes.name, value: net.attributes.key_name })))
      } catch { }
    })();
  }, []);

  return ({
    values,
    errors,
    networks,
    handleChange,
    isFormValid,
    handleSubmit,
  });
}



export default UseLostFunds;

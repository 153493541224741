import { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import config from '../config';
import { isNumber, specialFormat } from '../utils';

const useBanksAccounts = (props) => {
  const {
    startFetch,
    endFetch,
    onDelete,
    onCompleted,
    isGetBankAccounts,
    accountEdit,
    withdrawalAccount,
    isSnackBar,
  } = props;
  const { useUser, useFrequentAccounts, useBanks } = config.getInstance().getConfiguration();
  const {
    frequentAccounts,
    getBankAccounts,
    createBankAccount,
    updateBankAccount,
    deleteBankAccount,
    frequentAccountsIsReady,
  } = useFrequentAccounts();
  const {
    bankAccounts: { accounts },
  } = frequentAccounts;
  const { banks: { banksProfile: { banks } } } = useBanks();
  const { user } = useUser();
  const { iso_code_country } = user;
  const [bank, setBank] = useState('');
  const [bankKey, setBankKey] = useState('');
  const [accountType, setAccountType] = useState('');
  const [accountTypeKey, setAccountTypeKey] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [alias, setAlias] = useState('');
  const [isVisibleSnackBar, setVisibleSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [accountSelect, setAccountSelect] = useState(accounts[0]);
  const [typeDocument, changeTypeDocument] = useState('');
  const [typeDocumentKey, changeTypeDocumentKey] = useState('');
  const [documentNumber, changeDocumentNumber] = useState('');

  useEffect(() => {
    if (accountEdit) {
      setAccountNumber(accountEdit.account_number);
      setAlias(accountEdit.alias);
      setAccountTypeKey(accountEdit.account_type_code);
      setAccountType(accountEdit.account_type_name);
      setBankKey(accountEdit.bank_id);
      setBank(accountEdit.bank_name);

      if (iso_code_country === 'AR') {
        changeDocumentNumber(accountEdit.document_number);
        changeTypeDocument(accountEdit.document_type?.toUpperCase());
        changeTypeDocumentKey(accountEdit.document_type);
      }
    }
  }, [accountEdit]);

  useEffect(() => {
    if (withdrawalAccount) {
      setAccountSelect(withdrawalAccount);
    }
  }, [withdrawalAccount]);

  useEffect(() => {
    let isFetch = true;

    (async () => {
      try {
        if (isGetBankAccounts && isFetch) {
          await getBankAccounts(user);
          isFetch = false;
        }
      } catch { }
    })();

    return () => {
      isFetch = false;
    };
  }, []);

  const accountTypes = useMemo(() => {
    let items = [];

    if (bankKey) {
      banks.some((item) => {
        const validation = item.value === bankKey;
        if (validation) {
          items = item.account_types;
        }
        return validation;
      });
    }

    return items;
  }, [bankKey, banks]);

  const changeAccountSelect = (account) => {
    setAccountSelect(account);
  };

  const changeAccountNumber = (value) => {
    setAccountNumber(value);
  };

  const changeBank = (value) => {
    setBank(value);
  };

  const changeBankKey = (value) => {
    setBankKey(value);
  };

  const changeAccountType = (value) => {
    setAccountType(value);
  };

  const changeAccountTypeKey = (values) => {
    setAccountTypeKey(values);
  };

  const changeAlias = (value) => {
    setAlias(value);
  };

  const getAccountNumberLength = (iso_code = '') => {
    switch (iso_code.toUpperCase()) {
      case 'AR':
        return {
          min: 22,
          max: 22,
        }
      case 'MX':
        return {
          min: 18,
          max: 18,
        }
      default:
        return {
          min: 5,
          max: 25,
        }
    }
  };

  const errors = useMemo(() => {

    const accountLenght = getAccountNumberLength(iso_code_country);
    const specialFormat = /^(\S)[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ0-9\s]*(\S)$/;

    return {
      alias: alias ?
      !specialFormat.test(alias) ?
        'No puede comenzar ni terminar con espacios en blanco ni caracteres especiales' :
        alias.length < 3 ?
          'Mínimo 3 caracteres' :
          alias.length > 14 ?
            '14 caracteres máximo' :
            !specialFormat.test(alias) ?
            'No se permiten caracteres especiales' :
            '' :
        '',
      accountNumber:
      !specialFormat.test(accountNumber) ?
        'No puede comenzar ni terminar con espacios en blanco ni caracteres especiales' :
      !isNumber(accountNumber)
        ? 'Solo números'
        : accountNumber && accountNumber.length < accountLenght.min
          ? `Mínimo ${accountLenght.min} caracteres`
          : accountNumber && accountNumber.length > accountLenght.max
            ? `${accountLenght.max} caracteres máximo`
            : '',
      documentNumber:
        !specialFormat.test(documentNumber) ?
          'No puede comenzar ni terminar con espacios en blanco ni caracteres especiales' :
        documentNumber && !(/^\d{11}$/.test(documentNumber))
        ? 'Código de 11 dígitos, sin guiones'
        : '',
    };
  }, [
    alias,
    accountNumber,
    documentNumber,
    iso_code_country,
  ]);

  const isFormValid = useMemo(() => {
    let validBase = (
      accountNumber &&
      isNumber(accountNumber) &&
      accountType &&
      alias &&
      accountTypeKey &&
      bank &&
      bankKey &&
      !errors.alias &&
      !errors.accountNumber
    );

    if (iso_code_country.toUpperCase() === 'AR') {
      validBase = (
        validBase &&
        typeDocument &&
        typeDocumentKey &&
        !errors.documentNumber &&
        documentNumber
      );
    }

    return !!validBase;
  }, [
    accountNumber,
    accountType,
    alias,
    accountTypeKey,
    bank,
    bankKey,
    errors,
    iso_code_country,
    typeDocument,
    typeDocumentKey,
    documentNumber,
  ]);

  const resetOwnAccountForm = useCallback(() => {
    setAccountNumber('');
    setAlias('');
    setBank('');
    setBankKey('');
    setAccountType('');
    setAccountTypeKey('');
    changeTypeDocument('');
    changeTypeDocumentKey('');
    changeDocumentNumber('');
  }, []);

  const createBankAccountHandler = useCallback(async () => {
    try {
      startFetch();
      const isEditOperation = accountEdit && accountEdit.hasOwnProperty('id');
      let account = null;
      const data = {
        account_number: accountNumber,
        account_type: accountType,
        account_type_code: accountTypeKey,
        bank_id: bankKey,
        alias,
      };

      if (iso_code_country === 'AR') {
        data.document_type = typeDocumentKey;
        data.document_number = documentNumber;
      }

      if (isSnackBar) {
        setVisibleSnackBar(true);
        setMessage(
          isEditOperation
            ? '¡Datos actualizados exitosamente!'
            : '¡Datos guardados exitosamente!',
        );

        setTimeout(async () => {
          try {
            if (isEditOperation) {
              account = await updateBankAccount(user, accountEdit.id, data);
            } else {
              account = await createBankAccount(user, data);
            }

            setVisibleSnackBar(false);
            onCompleted(account);
            endFetch();
          } catch (error) {
            endFetch(error);
          }
        }, 3000);
      } else {
        if (isEditOperation) {
          account = await updateBankAccount(user, accountEdit.id, data);
        } else {
          account = await createBankAccount(user, data);
        }
        onCompleted(account);
      }

      endFetch();
    } catch (error) {
      endFetch(error);
    }
  }, [
    accountNumber,
    accountType,
    alias,
    accountTypeKey,
    bank,
    bankKey,
    user,
    accountEdit,
    typeDocumentKey,
    documentNumber,
  ]);

  const deleteBankAccountHandler = useCallback(async () => {
    if (accountEdit && accountEdit.hasOwnProperty('id')) {
      try {
        startFetch();
        await deleteBankAccount(user.headers, accountEdit.id);
        onDelete();
        endFetch();
      } catch (error) {
        endFetch(error);
      }
    }
  }, [accountEdit]);

  return {
    errors,
    isVisibleSnackBar,
    message,
    accounts,
    accountSelect,
    bank,
    alias,
    accountNumber,
    bankKey,
    accountType,
    accountTypeKey,
    banks,
    accountTypes,
    typeDocument,
    typeDocumentKey,
    documentNumber,
    changeAccountNumber,
    changeBank,
    changeBankKey,
    changeAccountType,
    changeAccountTypeKey,
    changeAlias,
    changeAccountSelect,
    changeTypeDocument,
    changeTypeDocumentKey,
    changeDocumentNumber,
    banksAccountsIsReady: frequentAccountsIsReady,
    deleteBankAccount: deleteBankAccountHandler,
    createBankAccount: createBankAccountHandler,
    isFormValid,
    resetOwnAccountForm,
  };
};

useBanksAccounts.propTypes = {
  startFetch: PropTypes.func,
  endFetch: PropTypes.func,
  onDelete: PropTypes.func,
  onCompleted: PropTypes.func,
  isGetBankAccounts: PropTypes.bool,
  isSnackBar: PropTypes.bool,
  accountEdit: PropTypes.any,
  withdrawalAccount: PropTypes.any,
  errors: PropTypes.shape({
    comentary: PropTypes.string,
  }),
};

useBanksAccounts.defaultProps = {
  startFetch: () => { },
  endFetch: () => { },
  onDelete: () => { },
  onCompleted: () => { },
  isGetBankAccounts: false,
  isSnackBar: false,
  accountEdit: null,
  withdrawalAccount: null,
};

export default useBanksAccounts;
